<template>
  <main class="landing">
    <header class="row center-xs middle-xs menu-landing">
      <div class="container row between-xs middle-xs">
        <nav class="nav-landing middle-xs">
          <router-link :to="{ name: 'home' }" class="logo row start-xs font-oswald">
            Pague <br />
            com <br />
            bitcoin.
          </router-link>
          <ul>
            <li>
              <router-link :to="{ name: 'home' }">
                Início
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'about' }">
                Contato
              </router-link>
            </li>
          </ul>
        </nav>
        <div>
          <a href="#" @click="showRegisterModal" class="btn-landing small yellow border"
            >Cadastre-se Agora</a
          >
        </div>
      </div>
    </header>

    <section class="row center-xs first first-target">
      <div class="row middle-xs center-xs container">
        <div class="col-lg-6 col-xs-12 start-xs middle-xs content row overflow-hidden">
          <h2
            class="first-elements hidden-animate sub-title-landing font-roboto"
          >
            Baixe o app agora!
          </h2>
          <h1 class="first-elements hidden-animate title-landing font-oswald">
            Pague suas contas com bitcoins em <span>segundos!</span>
          </h1>
          <p
            class="first-elements hidden-animate description-landing font-roboto"
          >
            Utilize cripto para pagar contas do dia a dia e obtenha muitos
            benefícios!
          </p>
          <div class="row buttons">
            <a href="https://apps.apple.com/br/app/paguecombitcoin/id1511333416" target="_blank" class="btn-apple-element hidden-animate btn-landing yellow"
              >Download para <AppleIcon />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.paguecombitcoin.mobile&utm_source=website&utm_medium=badge" target="_blank" class="btn-android-element animate__fast hidden-animate btn-landing yellow"
              >Download para <AndroidIcon />
            </a>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 images center-xs">
          <img
            class="scroll-smartphone-first smartphone"
            src="/assets/img/landing/pague-image.png"
            alt=""
          />
          <img class="squares" src="/assets/img/landing/squares.png" alt="" />
        </div>
      </div>
    </section>

    <section class="row center-xs second second-target">
      <div class="row center-xs">
        <h1 class="title-landing row second-elements hidden-animate">
          Tudo que você precisa em um app só!
        </h1>
      </div>

      <div class="content container">
        <div class="row between-xs">
          <div class="left col-md-4 col-xs-12 center-xs">
            <CardLanding
              v-for="(card, index) in cards.slice(0, 3)"
              :key="index"
              :title="card.title"
              :description="card.description"
              :icon="card.icon"
              :value="index + 1"
              :class="[
                index === 1 ? 'self-start' : '',
                `card-${index}`,
                'hidden-animate',
              ]"
            />
          </div>
          <div class="col-md-4 col-xs-12 center-xs">
            <img
              class="second-elements hidden-animate"
              src="/assets/img/landing/pague-options.png"
              alt=""
            />
          </div>

          <div class="right col-md-4 col-xs-12 center-xs">
            <CardLanding
              v-for="(card, index) in cards.slice(3, 6)"
              :key="index"
              :title="card.title"
              :description="card.description"
              :icon="card.icon"
              :value="index + 4"
              :class="[
                index === 1 ? 'self-end' : '',
                `card-${index}`,
                'hidden-animate',
              ]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="third third-target">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <img class="third-img-element hidden-animate" src="/assets/img/landing/pague-hand.png" alt="" />
          </div>
          <div class="col-md-6 col-xs-12 row content">
            <h1 class="title-landing third-elements hidden-element">
              Por que a <span>Pague</span> é a sua melhor opção?
            </h1>
            <p class="description-landing font-roboto third-elements hidden-element">
              A Pague Com Bitcoin oferece uma gama de benefícios para seus
              usuários. Ao usar nossa plataforma você tem:
            </p>

            <ul class="advantage-landing third-elements hidden-element">
              <li
                class="font-roboto"
                v-for="(advantage, index) in advantages"
                :key="index"
              >
                <span class="ball"><CheckIcon /> </span>{{ advantage }}
              </li>
            </ul>

            <div>
              <button class="btn-large">
                <span> Baixar app agora </span>
                <MiniArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="fourth row center-xs fourth-target">
      <div class="fourth-title-element hidden-animate">
        <span class="badge-element badge hidden-animate">Fácil de usar</span>

        <h1 class="title-landing">
          Pague qualquer conta com bitcoin em
          <span class="font-roboto">3 Passos</span>
        </h1>
      </div>

      <div class="container relative fourth-elements hidden-animate">
        <span class="custom-hr" />
        <div class="row center-xs content">
          <div class="col-md-4 col-xs-12">
            <span class="ball-large">1</span>
            <h2 class="sub-title-landing">Inicie seu pedido</h2>
            <p class="description-landing">
              Selecione as contas que quer pagar, a rede que quer realizar a
              transação e gere qr code.
            </p>
          </div>
          <div class="col-md-4 col-xs-12">
            <span class="ball-large">2</span>
            <h2 class="sub-title-landing">Faça o pagamento</h2>
            <p class="description-landing">
              Depois que a pague receber as suas criptos, seu pagamento será
              concluído em segundos.
            </p>
          </div>
          <div class="col-md-4 col-xs-12">
            <span class="ball-large">3</span>
            <h2 class="sub-title-landing">Receba o cashback</h2>
            <p class="description-landing">
              Após a transação ser concluida, você receberá o cashback em sua
              conta Pague Com Bitcoin.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="fifth fifth-target">
      <span class="square" />
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <h2 class="sub-title-landing fifth-elements hidden-animate">Depoimentos</h2>
            <h1 class="title-landing fifth-elements hidden-animate">O que dizem sobre nós:</h1>
          </div>
          <div class="col-md-8 col-xs-12">
            <Swiper
              class="fifth-carousel-element"
              ref="swiper"
              :options="swiperOptions"
            >
              <SwiperSlide
                class="fifth-carousel-element-slide"
                v-for="(testimony, index) in testimonials"
                :key="`swiper-slide-${index}`"
              >
                <div class="slide-landing">
                  <div>
                    <img
                      class="image-profile"
                      :src="require(`../../public/assets/img/landing/${testimony.image}`)"
                      :alt="testimony.name"
                      width="50"
                    />
                  </div>

                  <div class="slide-testimony">
                    <QuotesIcon width="30" />
                    <p class="testimony">{{ testimony.description }}</p>
                    <p class="name">{{ testimony.name }}</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div class="row end-xs swiper-buttons fifth-elements hidden-animate">
              <button
                @click="$refs.swiper.swiperInstance.slideNext()"
                class="btn-circle-landing slide-button-next"
              >
                <ArrowIcon />
              </button>
              <button
                @click="$refs.swiper.swiperInstance.slidePrev()"
                class="btn-circle-landing prev slide-button-prev"
              >
                <ArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sixth sixth-target">
      <div class="mask">
        <div class="container overflow-hidden">
          <div class="row content">
            <h2 class="sub-title-landing sixth-elements hidden-animate">Baixe nosso aplicativo!</h2>
            <h1 class="title-landing sixth-elements hidden-animate">
              App disponível na PlayStore e Appstore!
            </h1>
            <p class="description-landing sixth-elements hidden-animate">
              Baixe agora o aplicativo da Pague Com Bitcoin para o dispositivo
              de sua preferência!
            </p>
            <div class="row buttons">
              <a href="" class="btn-landing blue btn-apple-element hidden-animate"
                >Download para <AppleIcon />
              </a>
              <a href="" class="btn-landing blue btn-android-element animate__fast hidden-animate"
                >Download para <AndroidIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapActions } from 'vuex';
import CardLanding from '@/components/Landing/CardLanding.vue';
import StarIcon from '@/components/Landing/StarIcon.vue';
import ChartIcon from '@/components/Landing/ChartIcon.vue';
import GraphicsIcon from '@/components/Landing/GraphicsIcon.vue';
import MapsIcon from '@/components/Landing/MapsIcon.vue';
import StorageIcon from '@/components/Landing/StorageIcon.vue';
import UsersIcon from '@/components/Landing/UsersIcon.vue';
import CheckIcon from '@/components/Landing/CheckIcon.vue';
import ArrowIcon from '@/components/Landing/ArrowIcon.vue';
import MiniArrowIcon from '@/components/Landing/MiniArrowIcon.vue';
import AppleIcon from '@/components/Landing/AppleIcon.vue';
import AndroidIcon from '@/components/Landing/AndroidIcon.vue';
import QuotesIcon from '@/components/Landing/QuotesIcon.vue';
import animateOnScrollBySection from '@/helpers/animateOnScrollBySection';

import 'swiper/swiper-bundle.css';
import 'animate.css/animate.min.css';

export default {
  name: 'LandingPage',
  components: {
    CardLanding,
    CheckIcon,
    ArrowIcon,
    AppleIcon,
    AndroidIcon,
    Swiper,
    SwiperSlide,
    QuotesIcon,
    MiniArrowIcon,
  },
  methods: {
    ...mapActions(['openRegisterModal']),
    openLoginModal () {
      this.isModalLoginVisible = true;
    },
    closeLoginModal () {
      this.isModalLoginVisible = false;
    },
    async showRegisterModal () {
      this.openRegisterModal();
      this.trackGTMEvent('register-modal-opened');
    },
    async trackGTMEvent (event) {
      if (event && this.$gtm && this.$gtm.enabled()) {
        await this.$gtm.trackEvent({
          category: 'register',
          action: event,
          label: event,
        });
      }
    },
  },
  data () {
    return {
      cards: [
        {
          title: 'Pague Boletos',
          description: 'Pague boletos com bitcoins em segundos.',
          icon: StarIcon,
        },
        {
          title: 'Conversão de Bitcoin',
          description: 'Converta bitcoins em reais e receba via Pix ou Ted.',
          icon: StorageIcon,
        },
        {
          title: 'Saque Para Terceiros',
          description:
            'Converta seus bitcoins em reais e mande por pix para quem você quiser.',
          icon: GraphicsIcon,
        },
        {
          title: 'Receba Pagamentos',
          description: 'Seu cliente paga em bitcoin e você em pix.',
          icon: UsersIcon,
        },
        {
          title: 'Compre Gift Cards',
          description: 'Adquira gift cards pagando com cripto.',
          icon: ChartIcon,
        },
        {
          title: 'Recarga de Celular',
          description: 'Recarregue o saldo do seu celular com cripto.',
          icon: MapsIcon,
        },
      ],

      swiperOptions: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: '.slide-button-next',
          prevEl: '.slide-button-prev',
        },
      },

      advantages: [
        'Conversão Cripto Para Real em Segundos',
        'Receba Qualquer Transação via Pix',
        'Menos Burocracia Que Exchanges',
        'Mais Seguro Que Qualquer Corretora',
        'Não Fazemos Custódia De Suas Criptos',
      ],

      testimonials: [
        {
          name: 'Maikon Libório',
          description:
            'Muito bom! Sem burocracia, só colocar a linha do boleto e ele já completa tudo, dá o valor exato de quanto tenho que enviar e só alegria.',
          image: 'maikon.png',
        },
        {
          name: 'Thalles Mitchell',
          description:
            'Processamento muito rápido! Sempre que precisei de atendimento, foi impecável! Utilizo há quase um ano e confio de olhos fechados!',
          image: 'thalles.png',
        },
        {
          name: 'Leonardo Vieira',
          description:
            'Plataforma excepxional! Um excelente sistema.Estou gostando muito!!!',
          image: 'leonardo.png',
        },
      ],
    };
  },
  mounted () {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    animateOnScrollBySection(
      '.first-target',
      'first-elements',
      'animate__slideInUp',
      'hidden-animate',
    );

    setTimeout(() => {
      animateOnScrollBySection(
        '.first-target',
        'btn-apple-element',
        'animate__fadeInLeft',
        'hidden-animate',
      );
    }, 1000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.first-target',
        'btn-android-element',
        'animate__fadeInLeft',
        'hidden-animate',
      );
    }, 2000);

    animateOnScrollBySection(
      '.first-target',
      'scroll-smartphone-first',
      'animate__zoomIn',
      'hidden-animate',
    );

    setTimeout(() => {
      animateOnScrollBySection(
        '.second-target',
        'second-elements',
        'animate__zoomIn',
        'hidden-animate',
      );
    }, 1000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.second-target',
        'card-0',
        'animate__fadeInUp',
        'hidden-animate',
      );
    }, 1000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.second-target',
        'card-1',
        'animate__fadeInUp',
        'hidden-animate',
      );
    }, 2000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.second-target',
        'card-2',
        'animate__fadeInUp',
        'hidden-animate',
      );
    }, 3000);

    animateOnScrollBySection(
      '.third-target',
      'third-img-element',
      'animate__fadeInLeft',
      'hidden-animate',
    );

    animateOnScrollBySection(
      '.third-target',
      'third-elements',
      'animate__slideInUp',
      'hidden-animate',
    );

    setTimeout(() => {
      animateOnScrollBySection(
        '.fourth-target',
        'fourth-title-element',
        'animate__slideInUp',
        'hidden-animate',
      );
    }, 5000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.fourth-target',
        'fourth-elements',
        'animate__slideInUp',
        'hidden-animate',
      );
    }, 5000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.fifth-target',
        'fifth-carousel-element',
        'animate__zoomIn',
        'hidden-animate',
      );
    }, 6000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.fifth-target',
        'fifth-elements',
        'animate__slideInUp',
        'hidden-animate',
      );
    }, 6000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.sixth-target',
        'sixth-elements',
        'animate__slideInUp',
        'hidden-animate',
      );
    }, 7000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.sixth-target',
        'btn-apple-element',
        'animate__fadeInLeft',
        'hidden-animate',
      );
    }, 7000);

    setTimeout(() => {
      animateOnScrollBySection(
        '.sixth-target',
        'btn-android-element',
        'animate__fadeInLeft',
        'hidden-animate',
      );
    }, 7000);
  },
};
</script>
