<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M1.66675 9.99992V36.6666L13.3334 29.9999L26.6667 36.6666L38.3334 29.9999V3.33325L26.6667 9.99992L13.3334 3.33325L1.66675 9.99992Z"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M26.6667 10V36.6667"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M13.3333 3.33325V29.9999"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'MapsIcon',
};
</script>
