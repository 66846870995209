function animateOnScrollBySection (
  sectionSelector,
  classToAnimate,
  animationClass,
  hiddenClass,
) {
  const sections = document.querySelectorAll(sectionSelector);

  function checkSections () {
    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();

      if (index === 0 && rect.top < window.innerHeight && rect.bottom > 0) {
        animateElementsInSection(
          section,
          classToAnimate,
          animationClass,
          hiddenClass,
        );
      }

      if (index !== 0 && rect.top <= window.innerHeight && rect.bottom > 0) {
        animateElementsInSection(
          section,
          classToAnimate,
          animationClass,
          hiddenClass,
        );
      }
    });
  }

  function animateElementsInSection (
    section,
    classToAnimate,
    animationClass,
    hiddenClass,
  ) {
    const elements = section.querySelectorAll(`.${classToAnimate}`);
    elements.forEach((element) => {
      if (!element.classList.contains(animationClass)) {
        element.classList.add('animate__animated', animationClass);
        element.classList.remove(classToAnimate);

        if (element.classList.contains(hiddenClass)) {
          element.classList.remove(hiddenClass);
        }
      }
    });
  }

  window.addEventListener('scroll', checkSections);

  checkSections();
}

export default animateOnScrollBySection;
