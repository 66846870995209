<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
  >
    <path
      d="M8.95498 7.75742L15.5546 7.75742M15.5546 7.75742L15.5546 14.3571M15.5546 7.75742L7.06936 16.2427"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'MiniArrowIcon',
};
</script>
