<template>
  <div class="card-landing">
    <div class="body">
      <div class="row">
        <div class="icon">
          <div>
            <component width="28" :is="icon" />
          </div>
          <div>
            <span class="ball" />
          </div>
        </div>

        <h2 class="title">{{ title }}</h2>
      </div>

      <div class="row">
        <p class="description">{{ description }}</p>
      </div>
      <p class="value">{{ value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardLanding',
  props: {
    title: String,
    description: String,
    icon: [Object, Function],
    value: [String, Number],
  },
};
</script>
