<template>
  <div class="invoice-summary-component">
    <div class="container">

      <div v-if='isLogged' class="row referral-row">
        <div class="col-xs-12">
          <h2>INDIQUE E GANHE</h2>
          <p>
            Copie e compartilhe seu link de rastreio único para amigos não cadastrados na PagueComBitcoin.
            Após o cadastro com seu link, você recebe uma comissão de {{ currentReferralFeeValue }}% sobre o valor de TODOS OS PEDIDOS
            finalizados pelas suas indicações. Saiba mais sobre nosso Programa de Indicação
            <router-link :to="{ name: 'terms', hash: '#programa-de-indicacao' }">aqui.</router-link>
          </p>

          <p class="referral-sugestion" v-if="!showReferralLink">Efetue um novo login para liberar seu link de indicação</p>
        </div>
        <div class="col-xs-12 content-copy" v-if="showReferralLink">
          <p>
            <a @click="copyToClipboard(`${url}?ref=${userData.partnerCode}`)" class="btn btn--secondary">
              COPIAR LINK
            </a>
          </p>
          <p v-if="copied" class="referral-copied">Link copiado!</p>
        </div>
      </div>

      <div class="row info-row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h2>
            Resumo do pedido
          </h2>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <ul>
            <h3>Informações do pedido:</h3>
              <li>Id: <span>{{ invoice._id }}</span></li>
              <li>Data e hora da criação: <span>{{ invoice.createdAt | datetime }}</span></li>
              <li>Cotação: <span>R$ {{ invoice.cryptoPrice | currency }}</span></li>
              <li>Valor do pedido em Real: <span>R$ {{ invoice.fiatAmount | currency }}</span></li>
              <li>Valor do pedido em Cripto: <span>{{ this.invoice.crypto || 'BTC' }} {{ this.invoice.cryptoAmount | cryptocurrency }}</span></li>
              <li>Taxa de conveniência: <span>R$ {{ invoice.fee | currency }}</span></li>
            </ul>
          </div>
          <div class="user-info col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <ul>
              <h3>Suas informações:</h3>
              <li>E-mail: <span>{{ invoice.email }}</span></li>
              <li v-if="invoice.user.name">Nome/Razão Social: <span>{{ invoice.user.name }}</span></li>
              <li v-if="invoice.user.phone">Celular: <span>{{ invoice.user.phone | phone }}</span></li>
              <li v-if="invoice.user.document">Documento: <span>{{ invoice.user.document | maskDocument }}</span></li>
            </ul>
          </div>
        </div>
      </div> <!-- .row user infos -->

      <div class="row service-row" v-if="billets.length > 0">
        <div class="header-info">
          <billet-icon size="30px" type="light" />
          <h3 class="title-icon">Pagamento de Boleto</h3>
        </div>

        <div class="invoice-item col-lg-6 col-md-6 col-sm-6 col-xs-12" v-for="item in billets" :key="item._id">
          <ul>
            <li>Linha digitável: <br/><span class="digitable-line">{{ item.billet.digitableLine }}</span></li>
            <li>Vencimento: <span>{{ item.billet.dueDate | date }}</span></li>
            <li>Desconto: <span>R$ {{ item.billet.discount | currency }}</span></li>
            <li>Juros/Multa: <span>R$ {{ item.billet.increase | currency }}</span></li>
            <li>Valor à pagar: <span>R$ {{ item.billet.value | currency }}</span></li>
            <li>Valor Total: <span>R$ {{ item.amount | currency }}</span></li>
            <li>Taxa: <span>R$ {{ item.fee | currency }}</span></li>
            <li>Andamento: <span>{{ $t(`invoices.items.status.${item.status}`) }}</span></li>
          </ul>
        </div>
      </div> <!-- .row billets -->

      <div class="row service-row" v-if="phoneRecharges.length > 0">
        <div class="header-info">
          <!-- <img src="/assets/img/checkout/smartphone.png" alt="" /> -->
          <cellphone-icon size="30px" type="light" />
          <h3 class="title-icon">Recarga de Celular</h3>
        </div>

        <div class="invoice-item col-lg-6 col-md-6 col-sm-6 col-xs-12" v-for="item in phoneRecharges" :key="item._id">
          <ul>
            <li>Operadora: <span>{{ item.recharge.operator }}</span></li>
            <li>DDD + Número: <span>({{ item.recharge.ddd }}) {{ item.recharge.phone | phone }}</span></li>
            <li>Valor: <span>R$ {{ item.amount | currency }}</span></li>
            <li>Taxa: <span>R$ {{ item.fee | currency }}</span></li>
            <li>Andamento: <span>{{ $t(`invoices.items.status.${item.status}`) }}</span></li>
          </ul>
        </div>
      </div> <!-- .row recharges -->

      <div class="row service-row" v-if="shipments.length > 0">
        <div class="header-info">
          <btc-icon size="30px" type="light" />
          <h3 class="title-icon">Conversão de Bitcoins</h3>
        </div>

        <div class="invoice-item col-lg-6 col-md-6 col-sm-6 col-xs-12" v-for="item in shipments" :key="item._id">
          <ul>
            <li v-if="item.shipment.isThirdParty">Terceiro:
              <span>
              {{ item.shipment && item.shipment.name ? item.shipment.name : '' }}
              </span>
            </li>
            <li v-if="item.shipment.isThirdParty">Documento: <span>{{ item.shipment.document | maskDocument }}</span></li>
            <li>Banco: <span>{{ getBankNameByCode(item.shipment.bank) }}</span></li>
            <li v-if="item.shipment && item.shipment.agency">Agência: <span>{{ item.shipment.agency }}</span></li>
            <li v-if="item.shipment && item.shipment.account">Conta: <span>{{ item.shipment.account }}</span></li>
            <li v-if="item.shipment && item.shipment.kind">Tipo de conta: <span>{{ item.shipment.kind }}</span></li>
            <li>Valor: <span>R$ {{ item.amount | currency }}</span></li>
            <li>Taxa: <span>R$ {{ item.fee | currency }}</span></li>
            <li>Andamento: <span>{{ $t(`invoices.items.status.${item.status}`) }}</span></li>
          </ul>
        </div>
      </div> <!-- .row shipments -->

      <div class="row service-row" v-if="pix.length > 0">
        <div class="header-info">
          <pix-icon size="30px" type="light" />
          <h3 class="title-icon">Pix</h3>
        </div>

        <div class="invoice-item col-lg-6 col-md-6 col-sm-6 col-xs-12" v-for="item in pix" :key="item._id">
          <ul>
            <li v-if="item.pix.isThirdParty">Terceiro:
              <span>
              {{ item.pix && item.pix.name ? item.pix.name : '' }}
              </span>
            </li>
            <li v-if="item.pix && item.pix.isThirdParty">Documento: <span>{{ item.pix.document | maskDocument }}</span></li>
            <li v-if="item.pix && item.pix.key">Chave: <span>{{ item.pix.key }}</span></li>
            <li v-if="item.pix && item.pix.bank">Banco: <span>{{ getBankNameByCode(item.pix.bank) }}</span></li>
            <li v-if="item.pix && item.pix.agency">Agência: <span>{{ item.pix.agency }}</span></li>
            <li v-if="item.pix && item.pix.account">Conta: <span>{{ item.pix.account }}</span></li>
            <li v-if="item.pix && item.pix.account">Tipo de conta: <span>{{ item.pix.kind || 'CC' }}</span></li>
            <li>Valor: <span>R$ {{ item.amount | currency }}</span></li>
            <li>Taxa: <span>R$ {{ item.fee | currency }}</span></li>
            <li>Andamento: <span>{{ $t(`invoices.items.status.${item.status}`) }}</span></li>
          </ul>
        </div>
      </div> <!-- .row shipments -->

      <div class="row service-row last-line" v-if="giftCards.length > 0">
        <div class="header-info">
          <gift-card-icon size="30px" type="light" />
          <h3 class="title-icon">Gift Card</h3>
        </div>

        <div class="invoice-item col-lg-6 col-md-6 col-sm-6 col-xs-12" v-for="item in giftCards" :key="item._id">
          <ul>
            <li>Gift Card: <span>{{ item.giftCard.operator }}</span></li>
            <li>Item: <span>{{ item.giftCard.cardName ? `[${item.giftCard.card}] ${item.giftCard.cardName}` : item.giftCard.card }}</span></li>
            <li>Valor: <span>R$ {{ item.amount | currency }}</span></li>
            <li>Taxa: <span>R$ {{ item.fee | currency }}</span></li>
            <li>Andamento: <span>{{ $t(`invoices.items.status.${item.status}`) }}</span></li>
          </ul>
        </div>
      </div> <!-- .row recharges GiftCards -->

      <div class="invoice-summary-action">
        <a href="javascript: window.print()" class="btn btn--secondary">Resumo em PDF</a>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Invoice from '@/model/Invoice';
import currency from '@/filters/currency';
import date from '@/filters/date';
import datetime from '@/filters/datetime';
import cryptocurrency from '@/filters/cryptocurrency';
import phone from '@/filters/phone';
import maskDocument from '@/filters/maskDocument';
import config from '@/config';
import GiftCardIcon from '../Shared/Icons/GiftCardIcon';
import CellphoneIcon from '../Shared/Icons/CellphoneIcon';
import BtcIcon from '../Shared/Icons/BtcIcon';
import PixIcon from '../Shared/Icons/PixIcon';
import BilletIcon from '../Shared/Icons/BilletIcon';

export default {
  name: 'InvoiceSummary',
  props: {
    invoice: { type: Invoice },
  },
  filters: {
    currency,
    cryptocurrency,
    date,
    datetime,
    phone,
    maskDocument,
  },
  components: {
    GiftCardIcon,
    CellphoneIcon,
    BtcIcon,
    PixIcon,
    BilletIcon,
  },
  data: () => ({
    copied: false,
    url: config.app.url,
  }),
  methods: {
    ...mapActions(['getBanks']),
    getDocumentKindName (kind) { return (kind === 'J') ? 'CNPJ' : 'CPF'; },
    copyToClipboard (value) {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copied = true;
    },
  },
  computed: {
    ...mapGetters(['getBankNameByCode', 'isLogged', 'userData', 'currentReferralFee']),
    currentReferralFeeValue () {
      return this.currentReferralFee ? currency(this.currentReferralFee) : '?';
    },
    billets () {
      return this.invoice.items.filter(item => item.kind === 'BILLET');
    },
    phoneRecharges () {
      return this.invoice.items.filter(item => item.kind === 'RECHARGE');
    },
    shipments () {
      return this.invoice.items.filter(item => item.kind === 'SHIPMENT');
    },
    pix () {
      return this.invoice.items.filter(item => item.kind === 'PIX');
    },
    giftCards () {
      return this.invoice.items.filter(item => item.kind === 'GIFTCARD');
    },
    showReferralLink () {
      return !(!this.userData || !this.userData.partnerCode);
    },
  },
  async created () {
    await this.getBanks();
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';

.invoice-summary-component {
  color: $slate-grey-color;
  @media print {
    font-size: 14px;
    .col-xs-12 {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  h3 {
    &.title-icon{
      margin: 0px 0px 0px 15px;
    }
  }
  font: {
    family: 'Roboto', sans-serif;
    size: 16px
  }
  @media only screen and (max-width: $grid-sm-max) {
    font-size: 14px
  }

  .container {
    @media only screen and (max-width: $grid-sm-max) {
      padding: 15px
    }

    .referral-title{
      font-weight: bold;
    }

    .referral-text{
      line-height: 1.4;
    }

    .info-row {
      padding-bottom: 20px;
    }

    .service-row {
      @media only screen and (max-width: $grid-sm-max) {
        padding: 30px 0 0;
      }
    }

    .service-row, .info-row {
      @media print {
        page-break-inside: avoid;
      }
      border-bottom: 1px solid $spotlight-color;
      margin: 0;

      .giftcard-icon {
        fill: $slate-grey-color;
        height: 59px;
      }

      &:first-of-type {
        padding-bottom: 30px;
      }

      &.last-line {
        border: none
      }

      .header-info {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 20px 0 0;
        @media only screen and (max-width: $grid-sm-max) {
          margin: 0 0 10px
        }
        img {
          @media only screen and (max-width: $grid-sm-max) {
            max-height: 50px
          }
          @media print {
            display: none;
          }
        }
        .name-info {
          color: $slate-grey-color;
          text-transform: uppercase;
          margin-left: 20px;
          font: {
            weight: bold;
            size: 18px
          }
          @media only screen and (max-width: $grid-sm-max) {
            font-size: 16px
          }
          @media print {
            margin: 0px;
          }
        }
      }

      ul {
        list-style: none;
        font-weight: bold;
        line-height: 1.4;
        li {
          &:first-child {
            margin-top: 12px
          }

          span {
            font-weight: 400
          }
        }
      }
    }

    .user-info {
      @media print { margin-top: 20px; }
    }

    .invoice-item {
      position: relative;
      padding: 0px 0px 20px 0;

      .digitable-line {
        overflow-wrap: anywhere;
        padding-right: 10px;
        display: block;
      }
    }
    .invoice-item:nth-child(n+4):after {
      content:"";
      background: $slate-grey-color;
      position: absolute;
      top: 0;
      left: 0;
      width: 60%;
      height: 1px;
    }
    @media only screen and (max-width: $grid-sm-max) {
      .invoice-item:nth-child(n+3):after {
        content:"";
        background: $slate-grey-color;
        position: absolute;
        top: 0;
        left: 0;
        width: 80%;
        height: 1px;
      }
    }

    .invoice-summary-action {
      display: flex;
      justify-content: center;
      margin: 20px 0 0;

      .btn-action {
        min-width: 179px;
        min-height: 37px;
        font-size: 1.1em;
        @media print { display: none; }
        @media only screen and (max-width: $grid-sm-max) {
          min-width: 113px;
          min-height: 24px;
          font-size: 0.8em
        }
      }
    }

    .referral-row{

      @media print {
        display: none;
      }
      .referral-sugestion{
        font-family: 'Oswald', 'Open Sans', Verdana, sans-serif;
        color: $spotlight-color;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
      }

      .referral-text, .referral-title{
        @media only screen and (max-width: $grid-sm-max) {
          text-align: center;
        }
      }

      .content-copy {
        .referral-copied{
          color: $primary-color;
          padding-left: 25px;
          margin-top: 5px;

          @media only screen and (max-width: $grid-sm-max) {
            padding-left: 0px;
          }
        }

        @media only screen and (max-width: $grid-sm-max) {
          padding-top: 0px;
          text-align: center;
        }
      }
    }

  }

}

</style>
