<template>
  <div class="sticky-button-component">
  <transition name="sticky-fade">
    <div class="sticky-fixed" v-if="isVisible">
      <div
        class="sticky-button"
        :class="{ 'mode-page-services' : $route.meta.hideNavigation }"
        @click="backToTop">
        <span />
      </div>
    </div>
  </transition>
  </div>
</template>
<script>
export default {
  name: 'StickyButton',
  data () {
    return {
      isVisible: false,
      isTopPage: true,
      visibleoffset: 0,
      visibleoffsetbottom: 0,
    };
  },
  mounted () {
    window.smoothscroll = () => {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll);
        window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)));
      }
    };
    window.addEventListener('scroll', this.catchScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.catchScroll);
  },
  methods: {
    scrollFn () {},
    catchScroll () {
      const pastTopOffset = window.pageYOffset > parseInt(this.visibleoffset);
      const pastBottomOffset = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - parseInt(this.visibleoffsetbottom);
      this.isVisible = parseInt(this.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset;
      this.scrollFn(this);
    },
    backToTop () {
      window.smoothscroll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.sticky-button-component {
  @media print {
    display: none;
  }
  cursor: pointer;

  .sticky-fade-enter-active,
  .sticky-fade-leave-active {
    transition: opacity .7s;
  }
  .sticky-fade-enter,
  .sticky-fade-leave-to {
    opacity: 0;
  }

  .sticky-fixed {
    position: fixed;
    bottom: 0;
    right: 80px;
    z-index: 99;
    @media (max-width: 415px) {
      right: 12px;
    }
    .sticky-button {
      position: absolute;
      height: 50px;
      width: 50px;
      border-radius: 40px;
      background: $spotlight-color;
      border: 3px solid $white-color;
      bottom: 15px;
      right: 30px;
      transform: rotate(180deg);
      @include flex-center;
      @media only screen and (max-width: $grid-md-max) {
        bottom: 65px;
        right: 0px;
      }

      &.mode-page-services {
        bottom: 10px
      }

      span {
        height: 14px;
        width: 14px;
        background: url("/assets/img/icons/icon-to-bottom.svg");
        background-size: 100% 100%;
      }
    }
  }
}

</style>
