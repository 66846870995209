<template>
  <section class="payment-component">
    <div class="container">
      <div class="card">
        <h1 class="payment-title">
          {{ this.invoice.network === 'BSC' ? $t('invoiceCheckout.titleBsc') : $t('invoiceCheckout.title') }}
        </h1>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 send-bitcoin">
              <h2 class="send-title">
                {{ $t('invoiceCheckout.sendBTCTitle') }}
                <div class="send-value">
                  <span>{{ this.invoice.cryptoAmount | cryptocurrency }}</span>
                  {{ this.invoice.crypto || $t('invoiceCheckout.valueBTC') }}
                </div>
              </h2>
              <input readonly type="text" :value="address" />
              <div class="send-action" v-if="this.invoice.network === 'BTC'">
                <ct-popover :item="popoverMessage" v-if="this.invoice.network === 'BTC'">
                  <a :href="linkAddress" class="btn btn--tertiary">{{ $t('invoiceCheckout.openWallet') }}</a>
                </ct-popover>
                <span href="" class="btn btn-action btn-mobile" @click="toggleQRCode">
                  <span v-if="!isQRCode">{{ $t('invoiceCheckout.showQRCode') }}</span>
                  <span v-if="isQRCode">{{ $t('invoiceCheckout.hideQRCode') }}</span>
                </span>
              </div>

              <div class="send-action-bsc" v-if="this.invoice.network === 'BSC'">
                <span class="pay-with">{{ $t('invoiceCheckout.payWith') }}</span>
              </div>

              <div class="send-action-bsc send-action-bsc-desktop" v-if="this.invoice.network === 'BSC'">
                <ct-popover :item="popoverMessageMetamask" class="z-index-5">
                  <a :href="linkAddressMetamask" target="_blank" class="btn btn--tertiary margin-bottom-5" :alt="$t('invoiceCheckout.metamaskAlt')">
                    {{ $t('invoiceCheckout.metamask') }}
                  </a>
                </ct-popover>
                <span class="pay-or">&nbsp;{{ $t('invoiceCheckout.payOr') }}&nbsp;</span>
                <ct-popover :item="popoverMessageTrust" class="margin-left-5">
                  <a :href="linkAddressTrust" target="_blank" class="btn btn--tertiary margin-bottom-5" :alt="$t('invoiceCheckout.trustWalletAlt')">
                    {{ $t('invoiceCheckout.trustWallet') }}
                  </a>
                </ct-popover>
              </div>

              <div class="send-action-mobile">
                <ct-popover :item="popoverMessageMetamask" class="z-index-5 margin-top-5">
                  <a :href="linkAddressMetamask" target="_blank" class="btn btn--tertiary margin-bottom-5" :alt="$t('invoiceCheckout.metamaskAlt')">
                    {{ $t('invoiceCheckout.metamask') }}
                  </a>
                </ct-popover>
              </div>
              <div class="send-action-mobile">
                <span class="pay-or margin-bottom-5">&nbsp;{{ $t('invoiceCheckout.payOr') }}&nbsp;</span>
              </div>
              <div class="send-action-mobile">
                <ct-popover :item="popoverMessageTrust" class="margin-left-5 margin-bottom-10">
                  <a :href="linkAddressTrust" target="_blank" class="btn btn--tertiary" :alt="$t('invoiceCheckout.trustWalletAlt')">
                    {{ $t('invoiceCheckout.trustWallet') }}
                  </a>
                </ct-popover>
              </div>

              <div class="send-details">
                <span>
                  {{ $t('invoiceCheckout.expired') }}
                  <span class="countdown">
                  <ct-countdown
                    :startDate="invoice.currentTime"
                    :finalDate="invoice.expiryAt"
                    :endMessage="countdownMessage"
                  />
                </span>
                </span>
              </div>
            </div>
            <div :class="['col-lg-4 col-md-4 col-sm-12 col-xs-12 code-qr', { 'show-qr-code-mobile' : isQRCode }]">
              <div class="code">
                <div class="qr">
                  <img :src="this.linkAddress ? `https://quickchart.io/qr?text=${this.linkAddress}&size=200` : '#'">
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 payment">
              <h2 class="details-payment-title">
                {{ $t('invoiceCheckout.detailsPayment') }}
              </h2>
              <span class="fiat-value"> R$ {{ invoice.fiatAmount | currency }}</span>
            </div>
            <div class="col-xs-12 alert" v-if="this.invoice.network === 'BEP20'">
              <h3>Atenção</h3>
              <p>Você optou por pagar usando o protocolo BEP20, da rede Binance Smart Chain.</p>
              <p>Se sua carteira NÃO for desta rede, não envie os Bitcoins pois suas moedas serão perdidas.</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Invoice from '@/model/Invoice';
import currency from '@/filters/currency';
import CtCountdown from '@/components/Shared/Countdown';
import cryptocurrency from '@/filters/cryptocurrency';
import CtPopover from '@/components/Shared/Popover';

export default {
  name: 'InvoiceCheckout',
  components: {
    CtCountdown,
    CtPopover,
  },
  data: () => ({
    countdownMessage: 'Expirado.',
    popoverMessage: {
      title: 'Atenção',
      detail: `Clique neste botão se possuir uma carteira bitcoin neste dispositivo, caso contrário basta copiar os
      dados para a carteira bitcoin que tiver e realizar o envio dos bitcoins.`,
    },
    popoverMessageMetamask: {
      title: 'Atenção',
      detail: `Clique neste botão se possuir uma carteira METAMASK neste dispositivo, caso contrário basta copiar os
      dados para a carteira que tiver e realizar o envio das criptomoedas.`,
    },
    popoverMessageTrust: {
      title: 'Atenção',
      detail: `Clique neste botão se possuir uma carteira TRUST neste dispositivo, caso contrário basta copiar os
      dados para a carteira que tiver e realizar o envio das criptomoedas.`,
    },
    isQRCode: false,
  }),
  filters: {
    currency,
    cryptocurrency,
  },
  props: {
    invoice: {
      type: Invoice,
    },
  },
  methods: {
    toggleQRCode () {
      this.isQRCode = !this.isQRCode;
    },
  },
  computed: {
    address () {
      const firstTransaction = this.invoice.transactions[0] || {};
      return firstTransaction.address || '';
    },
    linkAddress () {
      if (!this.address) {
        return '';
      }
      if (this.invoice.network === 'BTC') {
        return `bitcoin:${this.address}?amount=${this.invoice.cryptoAmount}`;
      }
      return `${this.address}?amount=${this.invoice.cryptoAmount}`;
    },
    linkAddressMetamask () {
      if (!this.address) {
        return '';
      }
      return `https://metamask.app.link/send/0x55d398326f99059fF775485246999027B3197955@56/transfer?address=${this.address}&value=${this.invoice.cryptoAmount}e18&uint256=${this.invoice.cryptoAmount}e18&amount=${this.invoice.cryptoAmount}`;
    },
    linkAddressTrust () {
      if (!this.address) {
        return '';
      }
      return `https://link.trustwallet.com/send?asset=c20000714_t0x55d398326f99059fF775485246999027B3197955&address=${this.address}&amount=${this.invoice.cryptoAmount}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../themes/variables';
@import "../../themes/mixins/layout";

.payment-component {
  @media print {
    display: none;
  }

  .alert {
    height: auto;
    display: block;
    padding: 10px;
    h3 {
      margin: 5px;
    }
    p {
      margin: 5px;
      line-height: 1em;
    }
    @media only screen and (max-width: $grid-lg-max) {
        text-align: left;
    }
  }

  .container {
    max-width: 1200px;
    .card {
      background: $light-grey-color;
      padding: 58px 0;
      @media only screen and (max-width: $grid-md-max) {
        padding: 30px 10px;
      }

      .payment-title {
        text-align: center;
        font-size: 34px;
        margin-top: 0px;
        margin-bottom: 40px;
        @media only screen and (max-width: $grid-sm-max) {
          font-size: 25px
        }
      }
      .card-body {
        .row {
          margin: 0;
          justify-content: center;

          .send-bitcoin {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 500px;
            @media only screen and (max-width: $grid-md-max) {
              max-width: 80%
            }
            @media only screen and (max-width: $grid-xs-max) {
              max-width: 100%
            }
            @media only screen and (max-width: $grid-sm-max) {
              border-bottom: 2px solid $dark-grey-color;
              margin-bottom: 15px;
            }
            .send-title {
              font-size: 30px;
              line-height: 1;
              text-align: center;
              @media only screen and (max-width: $grid-sm-max) {
                font-size: 21px
              }
              .send-value {
                margin: 0 0 18px;
                @media only screen and (max-width: $grid-sm-max) {
                  margin-bottom: 27px;
                }
                @media only screen and (max-width: $grid-xs-max) {
                  margin-bottom: 15px
                }
                span {
                  color: $secondary-color
                }
              }
            }

            input {
              border: 4px solid $spotlight-color;
              min-height: 70px;
              width: 100%;
              max-width: 500px;
              font-size: 1.3em;
              color: $slate-grey-color;
              background: transparent;
              text: {
                align: center
              }
              @include flex-center();
              @media only screen and (max-width: $grid-md-max) {
                max-width: 100%;
                min-height: 58px;
                font-size: 1em;
              }
            }

            .send-action {
              display: flex;
              justify-content: center;
              margin: 15px 0;

              @media only screen and (max-width: $grid-sm-max) {
                width: 100%;
                justify-content: space-between;
                flex-wrap: wrap;
              }

              .btn-action {
                background: $spotlight-color;
                padding: 10px 25px;
                @media only screen and (max-width: $grid-sm-max) {
                  padding-top: 8px;
                  padding-bottom: 8px;
                  font-size: 1em;
                  margin-top: 5px;
                }

                &:hover {
                  background: $white-color;
                }

                &.btn-mobile {
                  display: none;
                  @media only screen and (max-width: $grid-sm-max) {
                    display: block
                  }
                }
              }
            }

            @media only screen and (max-width: $grid-sm-max) {
              .send-action-mobile {
                display: flex;
                justify-content: center;
                .margin-top-5 {
                  margin-top: 5px;
                }
                .margin-bottom-5 {
                  margin-bottom: 5px;
                }
                .margin-bottom-10 {
                  margin-bottom: 10px;
                }
              }
              .send-action-bsc-desktop {
                display: none !important;
              }
            }

            @media only screen and (min-width: $grid-sm-max) {
              .send-action-mobile {
                display: none !important;
              }
              .send-action-bsc-desktop {
                display: flex;
                justify-content: center;
              }
            }

            .send-action-bsc {
              display: flex;
              justify-content: center;
              margin-top: 10px;
              margin-bottom: 2px;

              .pay-with {
                text-transform: uppercase;
                color: #003058;
                font-weight: 500;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                font-size: 1.3em;
              }

              .pay-or {
                text-transform: uppercase;
                color: #003058;
                font-weight: 500;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                font-size: 1.3em;
                margin-top: 10px;
                margin-left: 4px;
              }

              .z-index-5 {
                z-index: 5;
              }

              .margin-left-5 {
                margin-left: 5px;
              }

              .margin-bottom-5 {
                margin-bottom: 5px;
              }

              @media only screen and (max-width: $grid-sm-max) {
                .pay-or {
                  margin-top: 5px;
                }
              }

              .btn-action {
                background: $spotlight-color;
                padding: 10px 25px;
                @media only screen and (max-width: $grid-sm-max) {
                  padding-top: 8px;
                  padding-bottom: 8px;
                  font-size: 1em;
                  margin-top: 5px;
                }

                &:hover {
                  background: $white-color;
                }

                &.btn-mobile {
                  display: none;
                  @media only screen and (max-width: $grid-sm-max) {
                    display: block
                  }
                }
              }
            }

            .send-details {
              text-transform: uppercase;
              color: $spotlight-color;
              font-weight: 500;
              display: flex;
              width: 100%;
              font-size: 1.3em;
              @media only screen and (max-width: $grid-sm-max) {
                font-size: 11px;
                margin-bottom: 15px
              }
              span{
                text-align: center;
                margin: 0 auto;
                &.countdown {
                  margin-top: 7px;
                  color: $slate-grey-color;
                  font-weight: 400;
                  display: block;
                }
              }

              .countdown-component {
                margin-left: 5px;
              }
            }
          }

          .code-qr {
            @include flex-center();
            width: 250px;
            transition: height ease 0.2s;
            @media only screen and (max-width: $grid-md-max) {
              margin: 20px 0
            }
            @media only screen and (max-width: $grid-sm-max) {
              display: none
            }

            &.show-qr-code-mobile {
              display: flex;
            }

            .code {
              background: $white-color;
              width: 250px;
              height: 250px;
              display: flex;
              .qr {
                width: 200px;
                height: 200px;
                background: $text-color;
                margin: auto;
              }
            }
          }

          .payment {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 500px;
            @media only screen and (max-width: $grid-md-max) {
              max-width: 80%
            }
            @media only screen and (max-width: $grid-xs-max) {
              max-width: 100%
            }
            .details-payment-title {
              font-size: 30px;
              line-height: 1;
              text-align: center;
              min-height: 80px;
              @media only screen and (max-width: $grid-sm-max) {
                font-size: 21px
              }
              .payment-value {
                font-size: 13px;
                margin-bottom: 35px;
                @media only screen and (max-width: $grid-xs-max) {
                  margin-bottom: 15px
                }
              }
            }

            input,
            .fiat-value {
              border: 4px solid $spotlight-color;
              min-height: 70px;
              width: 100%;
              max-width: 400px;
              color: $slate-grey-color;
              font-size: 1.5em;
              background: transparent;
              text: {
                transform: uppercase;
                align: center
              }
              @include flex-center();
              @media only screen and (max-width: $grid-md-max) {
                max-width: 100%;
                min-height: 58px;
                font-size: 1.1em;
              }
            }
          }
        }
      }
    }
  }
}

</style>
