import http from '@/services/HttpService';
import moment from 'moment';

export default {
  async getInvoiceDetail (id) {
    try {
      const provider = http();
      const res = await provider.get(`/api/v1/invoices/${id}`);
      return res.data && res.data.data ? res.data.data : {};
    } catch (error) {
      return error;
    }
  },
  async getValidAltcoins () {
    try {
      const provider = http();
      const res = await provider.get('/api/v1/invoices/altcoins');
      return res.data && res.data.data ? res.data.data : [];
    } catch (error) {
      return error;
    }
  },
  async getLastInvoice (user = {}) {
    try {
      const provider = http();
      let url = '/api/v1/invoices?limit=1&orderBy=createdAt&orderByDirection=desc&populate[]=userId&populate[]=transactions';
      if (user && user._id) {
        const filterSearch = JSON.stringify({
          field: 'userId',
          operation: '=',
          value: user._id,
        });
        url += `&filters[]=${filterSearch}`;
      }
      const res = await provider.get(url);
      return res.data && res.data.data ? res.data.data[0] : {};
    } catch (error) {
      return error;
    }
  },
  async createInvoice (invoiceData, userData) {
    try {
      const provider = http();
      const res = await provider.post(
        '/api/v1/invoices/',
        transformInvoiceData(invoiceData, userData),
      );
      return { status: 'SUCCESS', id: res.data.data._id };
    } catch (error) {
      return {
        status: 'ERROR',
        message: error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : '',
        errors: error.response && error.response.data && error.response.data.errors
          ? error.response.data.errors
          : [],
        errorCode: error.response && error.response.status ? error.response.status : '',
      };
    }
  },
};

function formatKey (key, keyType) {
  if (['EMAIL', 'UUID'].includes(keyType)) {
    return String(key).toLowerCase();
  }

  if (['DOCUMENT'].includes(keyType)) {
    return String(key).replace(/\D+/g, '');
  }

  if (['PHONE'].includes(keyType)) {
    return `+55${String(key).replace(/\D/g, '')}`;
  }

  return key;
}

function transformInvoiceData (invoiceData, userData) {
  const billets = invoiceData.billets.map(billet => {
    return {
      digitableLine: billet.digitableLine,
      dueDate: moment(billet.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      value: billet.amount || 0,
      discount: billet.discount || 0,
      increase: billet.addition || 0,
      uuid: billet.uuid || null,
    };
  });
  const recharges = invoiceData.recharges.map(recharge => {
    return {
      operator: recharge.operator,
      ddd: recharge.ddd,
      phone: recharge.number,
      amount: recharge.amount,
      uuid: recharge.uuid || null,
    };
  });
  const shipments = invoiceData.sellBtc.map(shipment => {
    return {
      name: userData.name,
      document: userData.document,
      bank: shipment.bank,
      agency: shipment.agency,
      account: shipment.account,
      operation: shipment.operation,
      kind: shipment.kind,
      amount: shipment.amount,
      isThirdParty: shipment.isThirdParty,
      thirdParty: {
        name: shipment.name || null,
        document: shipment.document || null,
        email: shipment.email || null,
        purpose: shipment.purpose || null,
      },
      uuid: shipment.uuid || null,
    };
  });
  const pix = invoiceData.pix.map(item => {
    return {
      name: userData.name,
      document: userData.document,
      type: item.type || 'KEY',
      keyType: item.keyType,
      key: formatKey(item.key, item.keyType),
      brcode: item.brcode || null,
      amount: item.amount,
      isThirdParty: item.isThirdParty,
      thirdParty: {
        name: item.name || null,
        document: item.document || null,
        email: item.email || null,
        purpose: item.purpose || null,
      },
      uuid: item.uuid || null,
    };
  });
  const giftCards = invoiceData.giftCards.map(giftCard => {
    return {
      operator: giftCard.operator,
      card: giftCard.card,
      amount: giftCard.amount,
      uuid: giftCard.uuid || null,
    };
  });
  return {
    invoice: {
      network: invoiceData.network || 'BTC',
      altcoin: invoiceData.network === 'BSC' && invoiceData.altcoin ? invoiceData.altcoin : undefined,
      items: {
        billets: billets,
        recharges: recharges,
        shipments: shipments,
        giftCards: giftCards,
        pix: pix,
      },
    },
  };
}
