<template>
  <div class="confirm-bsc-component">
    <ct-modal>
      <div slot="button">
        <div class="btn-close" @click="$emit('close')">
          <span class="icon icon-close"></span>
        </div>
      </div>
      <div class="card-modal">
        <div class="modal-title">Atenção</div>

        <p class="text-content">
          {{ network === 'BSC' ? 'Você optou por pagar usando uma criptomoeda da rede Binance Smart Chain.' : 'Você optou por pagar usando o protocolo BEP20, da rede Binance Smart Chain.'}}
        </p>

        <p class="text-content">
          {{
            network === 'BSC' ?
            'Se sua carteira NÃO for desta rede, não envie as criptomoedas, pois as mesmas serão perdidas. Caso sua carteira não seja BSC, clique em cancelar e escolha outra rede para finalizar seu pedido.' :
            'Se sua carteira NÃO for desta rede, não envie os Bitcoins pois suas moedas serão perdidas. Caso sua carteira não seja BEP20, clique em cancelar e escolha a rede Bitcoin para finalizar seu pedido.'
          }}
        </p>

        <div class="actions">
          <a class="btn btn--default" @click="close">Cancelar</a>
          <a class="btn btn--secondary" @click="confirm">Ok, Prosseguir</a>
        </div>
      </div>
    </ct-modal>
  </div>
</template>

<script>
import CtModal from '../Shared/Modal';
import config from '@/config';

export default {
  name: 'ConfirmBEP20Modal',
  data: () => ({
    copied: false,
    url: config.app.url,
  }),
  props: {
    network: {
      type: String,
      required: true,
    },
  },
  methods: {
    close () {
      this.$emit('close');
    },
    confirm () {
      this.$emit('confirm');
    },
  },
  components: {
    CtModal,
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.confirm-bsc-component {
  .modal {
    .btn-close {
      background: $primary-color;
      position: absolute;
      cursor: pointer;
      z-index: 3000;
      @include flex-center();
      width: 51px;
      height: 51px;
      top: -25px;
      left: 162px;
      @media only screen and (max-width: $grid-xs-max) {
        width: 34px;
        height: 34px;
        top: -17px;
        left: 112px;
      }

      .icon {
        display: flex;
        background: $spotlight-color;
        width: 18px;
        height: 18px;
        @media only screen and (max-width: $grid-xs-max) {
          width: 12px;
          height: 12px;
        }
        &.icon-close {
          mask: url('/assets/img/icons/icon-close.svg')no-repeat center;
          mask-size: 100% 100%;
        }
      }
    }
    .modal-content {
      .card-modal {
        width: 380px;
        background: $spotlight-color;
        padding: 25px;
        @media only screen and (max-width: $grid-xs-max) {
          padding: 15px;
          width: 260px;
        }

        .modal-title {
          color: $primary-color;
          text-align: center;
          margin: 10px 30px 25px;
          line-height: 1;
          text-transform: uppercase;
          font: {
            size: 23px;
            weight: 400
          }
        }
        .text-content{
          font-family: 'Roboto', sans-serif;
          color: $white-color;
          text-transform: none;
          font-size: 16px;
          text-align: center;
          margin-bottom: 10px;
          line-height: 1.3em;
          &.sugestion-text{
            margin-top: 25px;
          }

          @media screen and (max-width: $grid-xs-max) {
            font-size: 14px;
          }
        }
        .actions {
          margin-top: 40px;
          width: 100%;
          text-align: right;
          .btn {
            margin: 0 0 0 15px;
          }
        }
      }
    }
  }
}

</style>
