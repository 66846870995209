export default {
  SET_CURRENT_INVOICE (state, invoice) {
    state.currentInvoice = invoice;
  },
  SET_CURRENT_ALTCOINS (state, altcoins) {
    state.currentAltcoins = altcoins || [];
  },
  SET_INVOICE_ALTCOIN (state, invoiceAltcoin) {
    state.invoiceAltcoin = invoiceAltcoin || null;
  },
};
