import { format, isValid } from 'date-fns';

const formatFilter = (date) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  if (!isValid(date)) return '';
  return format(date, 'dd/MM/yyyy');
};

export default formatFilter;
