<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <rect width="28" height="28" rx="14" fill="#CBFB45"></rect>
    <path
      d="M8.75 14.75L11.75 17.75L19.25 10.25"
      stroke="#1D1D1F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'ChekIcon',
};
</script>
