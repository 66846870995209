<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="38"
    viewBox="0 0 40 38"
    fill="none"
  >
    <path
      d="M35.3501 26.4832C34.2898 28.9907 32.6314 31.2002 30.5199 32.9187C28.4084 34.6372 25.9081 35.8123 23.2375 36.3412C20.5669 36.8701 17.8075 36.7368 15.2003 35.9529C12.5932 35.169 10.2178 33.7583 8.2818 31.8443C6.34579 29.9303 4.90813 27.5711 4.09449 24.9731C3.28086 22.3751 3.11604 19.6174 3.61443 16.9409C4.11283 14.2645 5.25926 11.7509 6.95351 9.61994C8.64776 7.48894 10.8382 5.80542 13.3334 4.71655"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M36.6667 19.9999C36.6667 17.8112 36.2356 15.644 35.398 13.6219C34.5604 11.5998 33.3328 9.76245 31.7851 8.21481C30.2375 6.66716 28.4002 5.4395 26.3781 4.60193C24.356 3.76435 22.1887 3.33325 20 3.33325V19.9999H36.6667Z"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'ChartIcon',
};
</script>
