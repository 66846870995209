<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M33.3334 23.3333H6.66671C4.82576 23.3333 3.33337 24.8256 3.33337 26.6666V33.3333C3.33337 35.1742 4.82576 36.6666 6.66671 36.6666H33.3334C35.1743 36.6666 36.6667 35.1742 36.6667 33.3333V26.6666C36.6667 24.8256 35.1743 23.3333 33.3334 23.3333Z"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M10 30H10.0167"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M33.3334 3.33325H6.66671C4.82576 3.33325 3.33337 4.82564 3.33337 6.66659V13.3333C3.33337 15.1742 4.82576 16.6666 6.66671 16.6666H33.3334C35.1743 16.6666 36.6667 15.1742 36.6667 13.3333V6.66659C36.6667 4.82564 35.1743 3.33325 33.3334 3.33325Z"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M10 10H10.0167"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'StorageIcon',
};
</script>
