<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M38.3334 34.9999V31.6666C38.3323 30.1894 37.8406 28.7545 36.9356 27.5871C36.0307 26.4196 34.7636 25.5858 33.3334 25.2166"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M28.3333 35V31.6667C28.3333 29.8986 27.6309 28.2029 26.3807 26.9526C25.1304 25.7024 23.4347 25 21.6666 25H8.33329C6.56518 25 4.86949 25.7024 3.61925 26.9526C2.36901 28.2029 1.66663 29.8986 1.66663 31.6667V35"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M26.6666 5.21655C28.1007 5.58372 29.3717 6.41772 30.2794 7.58707C31.187 8.75642 31.6797 10.1946 31.6797 11.6749C31.6797 13.1552 31.187 14.5934 30.2794 15.7627C29.3717 16.9321 28.1007 17.7661 26.6666 18.1332"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M15 18.3333C18.6819 18.3333 21.6667 15.3486 21.6667 11.6667C21.6667 7.98477 18.6819 5 15 5C11.3181 5 8.33337 7.98477 8.33337 11.6667C8.33337 15.3486 11.3181 18.3333 15 18.3333Z"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'UsersIcon',
};
</script>
