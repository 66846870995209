<template>
  <div class="register-verify-component">
    <h1 v-if="registrationStep === 'VERIFY'">
      {{ $t('register.steps.verify.greeting') }} {{ user.name }}, {{ $t('register.steps.verify.registerDone') }}
    </h1>
    <h1 v-if="registrationStep === 'VERIFY-ERROR'">
      {{ $t('register.steps.verifyError.title') }}
    </h1>

    <p id="code-sent" v-if="registrationStep === 'VERIFY'" v-html="$t('register.steps.verify.codeSent', { type: settings && settings.providerMessages ? settings.providerMessages : 'SMS' })">
    </p>
    <p id="code-sent" v-if="registrationStep === 'VERIFY-ERROR'">
      {{ $t('register.steps.verifyError.errorMessage') }}
    </p>

    <div class="form-group">
      <input
        :class="['form-control form-control-sm', { 'is-invalid': errors.has('code')}]"
        id="verificationCode"
        type="text"
        name="code"
        v-model="verificationCode"
        v-validate="'required'"
      />
      <span class="msg-error">{{ errors.first('code') }}</span>
    </div>

    <button @click.prevent="verifyUserAndEmitEvent" id="btn-send" class="btn btn--secondary block-center">
      {{ $t('register.steps.verify.buttons.send') }}
    </button>

    <p id="resend-code" v-html="$t('register.steps.verify.resendCode')"></p>

    <div class="btn-container">
      <button @click.prevent @click="resendVerify" :class="`btn btn--secondary ${!showResend ? 'button-disabled' : ''}`" :disabled="!showResend">
        <ct-countdown
          :startDate="startCountdown"
          :finalDate="endCountdown"
          :endMessage="''"
          v-if="!showResend"
        />
        {{ showResend ? $t('register.steps.verify.buttons.resend') : '' }}
      </button>
      <button @click.prevent @click="returnToRegisterStep" class="btn btn--secondary">
        {{ $t('register.steps.verify.buttons.back') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CtCountdown from '@/components/Shared/Countdown';

export default {
  name: 'RegisterVerify',
  components: {
    CtCountdown,
  },
  data: () => ({
    verificationCode: '',
    startCountdown: Date.now(),
    endCountdown: (Date.now() + 30 * 1000),
    interval: null,
    timer: 0,
    showResend: false,
  }),
  computed: {
    ...mapState({
      user: state => state.register.registeringUser,
      registrationStep: state => state.register.registrationStep,
      settings: (state) => state.settings.settings || {},
    }),
  },
  methods: {
    ...mapActions(['verifyUser', 'registerUser', 'returnToRegisterStep']),
    initializeInterval () {
      this.interval = setInterval(() => {
        this.timer = this.timer - 1000;
        if (this.timer < 0) {
          clearInterval(this.interval);
          this.showResend = true;
        }
      }, 1000);
    },
    async verifyUserAndEmitEvent () {
      await this.verifyUser(this.verificationCode);
      await this.trackGTMEvent('validation-finished');
    },
    async resendVerify () {
      await this.registerUser(this.user);
      this.showResend = false;
      this.startCountdown = Date.now();
      this.endCountdown = (Date.now() + 30 * 1000);
      this.timer = this.endCountdown - this.startCountdown;
      this.initializeInterval();
      await this.trackGTMEvent('validation-resend');
    },
    async trackGTMEvent (event) {
      if (this.$gtm && this.$gtm.enabled()) {
        await this.$gtm.trackEvent({
          category: 'register',
          action: `register-${event}`,
          label: `register-${event}`,
        });
      }
    },
  },
  created () {
    this.timer = this.endCountdown - this.startCountdown;
    this.initializeInterval();
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import "../../themes/variables";
@import "../../themes/mixins/layout";

.register-verify-component {
  padding: 25px;
  @media screen and (max-width: $grid-xs-max) {
    padding: 0px;
  }

  h1, p,  a {
    color: $tertiary-color;
    line-height: 1;
  }
  h1 {
    margin-bottom: 25px;
    text-transform: uppercase;
    @media screen and (max-width: $grid-xs-max) {
      margin-bottom: 15px;
    }
  }
  p#code-sent {
    font-size: 21px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 20px;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 17px;
      line-height: 17px;
      margin-bottom: 15px;
    }
  }
  p#resend-code {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 25px;
    @media screen and (max-width: $grid-xs-max) {
      font-size: 15px;
      line-height: 16px;
      margin-bottom: 15px;
    }
  }

  input#verificationCode {
    max-width: none;
  }

  .btn-container {
    display: flex;
    .btn.btn--secondary {
      flex: 1;
      &:first-child {
        margin-right: 15px;
      }
    }
    .button-disabled {
      background: #bb5333;
    }
  }
  #btn-send {
    margin: 20px auto;
    width: 50%;
  }
}

</style>
